<template>
  <div class="position-relative">
    <CRow class="m-0">
      <CCol
        class="p-0"
        id="bg-img"
        style="max-width: 680px"
        v-if="!IS_DOH_THEME"
      >
        <img v-if="!isAcronymBasedLogin" :src="getBanner" alt="bg" />
        <img
          v-else
          :src="getBanner"
          :style="`width:${getScreenSize.width - 15}px;height:756px`"
          @error="
            $event.target.src = '/img/new_home_bg.png';
            $event.target.style = '';
          "
          alt="bg"
        />
      </CCol>
      <CCol class="p-0">
        <CContainer class="forgot-container">
          <CCardGroup
            class="d-flex card-group has-loading-overlay justify-content-center"
          >
            <CCard class="p-4 border-0" style="max-width: 400px">
              <CCardBody>
                <ValidationObserver ref="forgot" v-slot="{ handleSubmit }">
                  <CForm @submit.prevent="handleSubmit(onSubmit)">
                    <div v-if="IS_DOH_THEME" style="text-align: center">
                      <img
                        style="width: 85%"
                        :src="getHeaderLogo"
                        @error="$event.target.src = '/img/doh.png'"
                        alt="logo"
                      />
                    </div>
                    <img
                      id="loginLogo"
                      v-else
                      style="width: 100%"
                      :src="getLoginLogo"
                      @error="$event.target.src = '/img/talentfind_logo2.png'"
                      alt="logo"
                    />
                    <p class="m-3 text-center title font-weight-bold">
                      Forgot Password?
                    </p>
                    <div>
                      <label class="required">Email Address</label>
                      <div style="min-width: 65%">
                        <ValidationProvider
                          rules="required|email"
                          v-slot="{ errors }"
                        >
                          <TextInput
                            name="email"
                            :value="email"
                            @input="handleInput"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </div>
                    <CRow class="mt-3 mb-4 justify-content-end">
                      <CButton
                        type="button"
                        color="secondary"
                        class="px-3 text-white mr-3"
                        @click="redirectToLogin"
                        >{{ "Cancel" }}</CButton
                      >
                      <CButton
                        type="submit"
                        color="primary"
                        class="px-4 mr-3"
                        >{{ "Reset" }}</CButton
                      >
                    </CRow>
                  </CForm>
                </ValidationObserver>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CContainer>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import TextInput from "@/components/reusable/Fields/TextInput";
import { extend } from "vee-validate";
import { email } from "vee-validate/dist/rules";
import { LOGIN_URL, IS_DOH_THEME } from "@/helpers/helper";

extend("email", { ...email, message: "Invalid email" });
export default {
  name: "Forgot",
  components: {
    TextInput,
  },
  data() {
    return {
      IS_DOH_THEME,
      email: "",
    };
  },
  computed: {
    ...mapGetters(["getUserData", "getImages", "isAcronymBasedLogin"]),
    getBanner() {
      return this.getImages?.banner;
    },
    getHeaderLogo() {
      return this.getImages.header_logo;
    },
    getLoginLogo() {
      return this.getImages.login_logo;
    },
    getScreenSize() {
      return {
        width: screen.availWidth,
        height: screen.availHeight,
      };
    },
  },
  methods: {
    ...mapActions(["forgot", "showToast", "isEmailBelongsToAcronym"]),
    forgotSubmit() {
      this.isEmailBelongsToAcronym(this.email).then((res) => {
        if (res.data) {
          this.forgot({ email: this.email });
        }
      });
    },
    redirectToLogin() {
      this.$router.push({ path: LOGIN_URL() });
    },
    handleInput(name, value) {
      this.email = value;
    },
    async onSubmit() {
      const isValid = await this.$refs.forgot.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please enter a valid email",
        });
        return;
      }
      this.forgotSubmit();
    },
  },
};
</script>
<style lang="scss" scoped>
@media (max-width: 1000px) {
  #bg-img {
    display: none;
  }
}
.forgot-container {
  .card-group {
    margin-top: 125px;
    @media (max-width: 1000px) {
      margin-top: -10px;
    }
    .title {
      font-size: 22px;
      line-height: 28px;
    }
  }
}

.position-relative {
  margin-top: 63px;
}
@media screen and (min-device-width: 1024px) and (max-device-width: 1440px) {
  .position-relative {
    margin-top: 63px;
  }
}
@media screen and (min-device-width: 320px) and (max-device-width: 1024px) {
  .position-relative {
    margin-top: 32px;
  }
}
</style>
